import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const SectionVSLinkMaybe = props => {
  const { vsLink } = props.publicData;
  return vsLink ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.virtualSessionLinkTitle" />
      </h2>
      <a href={vsLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className={css.description}>
          {richText(vsLink, {
            longWordClass: css.longWord
          })}
        </p>
      </a>
    </div>
  ) : null;
};

export default SectionVSLinkMaybe;
