import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-england',
    predictionPlace: {
      address: 'England, UK',
      bounds: new LatLngBounds(
        new LatLng(55.81165979999999, 1.7629159),
        new LatLng(49.864741, -6.4185458)
      ),
    },
  },
  {
    id: 'default-scotland',
    predictionPlace: {
      address: 'Scotland, UK',
      bounds: new LatLngBounds(
        new LatLng(60.8607515, -0.7246751),
        new LatLng(54.6332381, -8.6500072)
      ),
    },
  },
  {
    id: 'default-wales',
    predictionPlace: {
      address: 'Wales, UK',
      bounds: new LatLngBounds(
        new LatLng(53.531201997068, -2.65008268983741),
        new LatLng(51.2856200033031, -5.41896792307291)
      ),
    },
  } 
];
