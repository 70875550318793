import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingFeaturesPanel.css';
import config from '../../config';

const FEATURES_NAME = 'categories';

class EditListingFeaturesPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      categories: [],
      services: [],
      serviceName: '',
      serviceDescription: '',
      serviceDurationHours: 0,
      serviceDurationMinutes: 0,
      inputsDisplay: false
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount() {
    const currentListing = ensureListing(this.props.listing);
    const { publicData } = currentListing.attributes;
    const services = publicData.services !== undefined ? publicData.services : this.state.services;
    const categories = publicData.categories !== undefined ? publicData.categories : this.state.categories;
    this.setState({services});
    this.setState({categories});
  }

  handleChangeInput(field, value) {
    this.setState({[field]: value});
  }

  removeService = (e) => {
    const newState = this.state.services.filter(item => item.key !== e.currentTarget.value);
    this.setState({services: newState});
  };

  onChangeForm = (e, v) => {
    v.length > 3 && v.splice(-1, 1);
    let categories = [];
    v.map(value => {
      const result = config.custom.categories.filter(category => category.label === value);
      categories.push(result[0].key);
    });
    this.setState({categories: categories})
  };

  onAddService = () => {
    this.setState({inputsDisplay: true});
    let services = this.state.services !== undefined && this.state.services.length > 0 ? this.state.services : [];
    if (this.state.serviceName.length > 0) {
      const key = this.state.serviceName.toLowerCase().replace(' ', '_').replace('-', '_');
      const duration = this.state.serviceDurationHours + this.state.serviceDurationMinutes / 60;
      if (duration > 0) {
        services.push({key: key, name: this.state.serviceName, description: this.state.serviceDescription, duration});
        this.setState({services: services});
        this.onSaveClearState();
      } else {
        alert('Service duration should not be 0');
      }
    }
  };

  onRemoveForm = () => {
    this.setState({inputsDisplay: false});
    this.onSaveClearState();
  };

  onSaveClearState = () => {
    this.setState({serviceName: ''});
    this.setState({serviceDescription: ''});
    this.setState({serviceDurationHours: 0});
    this.setState({serviceDurationMinutes: 0});
  };

  render() {
    const {
      rootClassName,
      className,
      listing,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingFeaturesPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingFeaturesPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
    );

    const categoriesKey = publicData && publicData.categories;
    const serviceDescription = publicData && publicData.serviceDescription ? publicData.serviceDescription : null;

    let categories = [];
    categoriesKey && categoriesKey.map(categoryKey => {
      const result = config.custom.categories.findIndex(category => category.key === categoryKey);
      categories.push(result);
    });
    const initialValues = { categories };

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingFeaturesForm
          className={css.form}
          name={FEATURES_NAME}
          initialValues={{initialValues , serviceDescription }}
          onSubmit={values => {
            const services = this.state.services !== undefined && this.state.services.length > 0 ? this.state.services : [];
            if (this.state.serviceName.length > 0) {
              const key = this.state.serviceName.toLowerCase().replace(' ', '_').replace('-', '_');
              const duration = this.state.serviceDurationHours + this.state.serviceDurationMinutes / 60;
              if (duration > 0) {
                services.push({key: key, name: this.state.serviceName, description: this.state.serviceDescription, duration});
                this.onSaveClearState();
                this.setState({inputsDisplay: false});
              } else {
                alert('Service duration should not be 0')
              }
            }
            const updatedValues = {
              publicData: {
                categories: this.state.categories,
                services: services,
              },
            };
            onSubmit(updatedValues);
          }}
          onChange={onChange}
          onChangeForm={this.onChangeForm}
          services={this.state.services}
          state={this.state}
          categories={this.state.categories}
          onAddService={this.onAddService}
          onRemoveForm={this.onRemoveForm}
          onChangeInputServiceName={this.onChangeInputServiceName}
          handleChangeInput={this.handleChangeInput}
          inputsDisplay={this.state.inputsDisplay}
          removeService={this.removeService}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          defaultValues={categories}
          disabled={this.state.categories.length <= 0}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { func, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  // listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  // submitButtonText: string.isRequired,
  // panelUpdated: bool.isRequired,
  // updateInProgress: bool.isRequired,
  // errors: object.isRequired,
};

export default EditListingFeaturesPanel;
