import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '../../components';
import clients_benefits from '../../assets/clients_benefits.png';
import discover_how_to_use_mitribe_wellness from '../../assets/discover_how_to_use_mitribe_wellness.png';
import discover_how_to_use_mitribe_wellness_1 from '../../assets/discover_how_to_use_mitribe_wellness_1.png';
import practitioners_benefits from '../../assets/practitioners_benefits.png';

import css from './SectionHowItWorks.css';

const SectionHowItWorks = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
        <br />
        <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>
      </div>
      <div className={css.createListingLink}>
        <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </OwnListingLink>
      </div>
      <div className={css.createListingLink}>
        <div>
          <div>
            <img className={css.imageForText} src={discover_how_to_use_mitribe_wellness_1} alt="discover_how_to_use_mitribe_wellness_1" />
            <h2 className={css.paragraphTitle}><FormattedMessage id="SectionHowItWorks.paragraph_1_title" /></h2>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_1_1" /></p>
            <p><b><FormattedMessage id="SectionHowItWorks.paragraph_1_2" /></b></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_1_3" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_1_5" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_1_6" /></p>
          </div>
          <div>
            <img className={css.imageForText} src={discover_how_to_use_mitribe_wellness} alt="discover_how_to_use_mitribe_wellness" />
            <h2 className={css.paragraphTitle}><FormattedMessage id="SectionHowItWorks.paragraph_2_title" /></h2>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_2_1" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_2_2" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_2_3" /></p>
          </div>
          {(currentUser && currentUser.attributes.profile.protectedData.roles.roles === 'Practitioner') || !currentUser ? <div>
            <img className={css.imageForText} src={practitioners_benefits} alt="practitioners_benefits" />
            <h2 className={css.paragraphTitle}><FormattedMessage id="SectionHowItWorks.paragraph_3_title" /></h2>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_3_1" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_3_2" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_3_3" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_3_4" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_3_5" /></p>
          </div> : null}
          {(currentUser && currentUser.attributes.profile.protectedData.roles.roles === 'Customer') || !currentUser ? <div>
            <img className={css.imageForText} src={clients_benefits} alt="clients_benefits" />
            <h2 className={css.paragraphTitle}><FormattedMessage id="SectionHowItWorks.paragraph_4_title" /></h2>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_4_1" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_4_6" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_4_2" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_4_3" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_4_4" /></p>
            <p><FormattedMessage id="SectionHowItWorks.paragraph_4_5" /></p>
          </div> : null}
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
