/*
 * Marketplace specific configuration.
 */

// export const categories = [
//   {
//     key: 'mind', checked: false, subcategories: [{ key: 'sub-mind-one', label: 'OneM', checked: false, price: 0 },
//       { key: 'sub-mind-two', label: 'Two', checked: false, price: 0  },
//       { key: 'sub-mind-three', label: 'Three', checked: false, price: 0  }], label: 'Mind',
//   },
//   {
//     key: 'body', checked: false, subcategories: [{ key: 'sub-body-one', label: 'OneB', checked: false, price: 0  },
//       { key: 'sub-body-two', label: 'Two', checked: false, price: 0  },
//       { key: 'sub-body-three', label: 'Three', checked: false, price: 0  }], label: 'Body',
//   },
//   {
//     key: 'soul', checked: false, subcategories: [{ key: 'sub-soul-one', label: 'OneS', checked: false, price: 0  },
//       { key: 'sub-soul-two', label: 'Two', checked: false, price: 0  },
//       { key: 'sub-soul-three', label: 'Three', checked: false, price: 0  }], label: 'Soul',
//   },
// ];

export const categories = [
  { key: 'access-consciousness', label: 'Access Consciousness' },
  { key: 'acupressure-acupuncture-cupping-moxibustion-gua-sha-sonopuncture-hijama', label: 'Acupressure / Acupuncture / Cupping / Moxibustion / Gua Sha / Sonopuncture / Hijama' },
  { key: 'addiction-treatments-coding-therapy', label: 'Addiction Treatments / Coding Therapy' },
  { key: 'allergy-therapy-naet)', label: 'Allergy Therapy / NAET (Nambudripad Allergy Elimination Technique)' },
  { key: 'angel-readings-channeling-akashic-records', label: 'Angel Readings / Channeling / Akashic Records' },
  { key: 'animal-therapy', label: 'Animal Therapy' },
  { key: 'apitherapy', label: 'Apitherapy' },
  { key: 'aromatherapy', label: 'Aromatherapy' },
  { key: 'art-therapy-colour-therapy', label: 'Art Therapy / Colour Therapy' },
  { key: 'asian-medicine-chinese-medicine-unani-medicine', label: 'Asian Medicine / Chinese Medicine / Unani Medicine' },
  { key: 'astrology', label: 'Astrology' },
  { key: 'ayurveda-siddha-medicine', label: 'Ayurveda / Siddha Medicine' },
  { key: 'bioenergetic-medicine', label: 'Bioenergetic Medicine' },
  { key: 'blood-urine-analysis-uropathy', label: 'Blood & Urine Analysis / Uropathy' },
  { key: 'bowen-technique', label: 'Bowen Technique' },
  { key: 'breath-work', label: 'Breath-work' },
  { key: 'chakra-aura-work-pranic-healing', label: 'Chakra & Aura Work /Pranic Healing' },
  { key: 'chiropractic', label: 'Chiropractic' },
  { key: 'coaching-life-coach-executive-coach', label: 'Coaching / Life Coach / Executive Coach' },
  { key: 'colon-therapies', label: 'Colon Therapies' },
  { key: 'cook-chef-nutritional-therapies', label: 'Cook / Chef / Nutritional Therapies' },
  { key: 'craniosacral-therapy', label: 'Craniosacral Therapy' },
  { key: 'crystal-healing-crystal-layouts', label: 'Crystal Healing / Crystal Layouts' },
  { key: 'dance-therapy-drumming', label: 'Dance Therapy / Drumming' },
  { key: 'detoxification-cleanse-chelation-therapy', label: 'Detoxification / Cleanse / Chelation Therapy' },
  { key: 'drama-therapy', label: 'Drama Therapy' },
  { key: 'ear-hearing-therapies-auriculotherapy', label: 'Ear / Hearing Therapies / Auriculotherapy' },
  { key: 'eft-tapping-emotion-code', label: 'EFT (Emotional Freedom Techniques) / Tapping / Emotion Code' },
  { key: 'electrohomeopathy', label: 'Electrohomeopathy' },
  { key: 'electromagnetic-therapy', label: 'Electromagnetic Therapy' },
  { key: 'energy-clearing-dowsing-feng-shui', label: 'Energy Clearing / Dowsing / Feng Shui' },
  { key: 'energy-healing-reiki-therapeutic-touch-jin-shin-jyutsu-polarity-therapy-quantum-techniques', label: 'Energy Healing / Reiki / Therapeutic Touch / Jin Shin Jyutsu Polarity Therapy / Quantum Techniques' },
  { key: 'eye-treatments-or-therapy-bates-method-rapid-eye-technology-iridology-sclerology', label: 'Eye Treatments or Therapy / Bates method / Rapid Eye Technology / Iridology / Sclerology' },
  { key: 'facial-gua-sha-acupuncture-cupping-hijama', label: 'Facial / Gua Sha / Acupuncture / Cupping / Hijama' },
  { key: 'feldenkrais-method', label: 'Feldenkrais Method' },
  { key: 'flower-therapy-bach-flower-therapy-flower-essence-horticultural-therapy', label: 'Flower Therapy / Bach Flower Therapy / Flower Essence / Horticultural Therapy' },
  { key: 'germanic-new-medicine', label: 'Germanic New Medicine' },
  { key: 'graphology', label: 'Graphology' },
  { key: 'hair-analysis-hair-therapy', label: 'Hair Analysis / Hair Therapy' },
  { key: 'havening', label: 'Havening' },
  { key: 'herbal-therapy-herbalist', label: 'Herbal Therapy / Herbalist' },
  { key: 'holistic-dentistry', label: 'Holistic Dentistry' },
  { key: 'holistic-medicine', label: 'Holistic Medicine' },
  { key: 'homeopathy', label: 'Homeopathy' },
  { key: 'hypnotherapy-neuro-linguistic-programming', label: 'Hypnotherapy / NeuroLinguistic Programming (NLP)' },
  { key: 'kinesiology', label: 'Kinesiology' },
  { key: 'kinetics-holographic-kinetic', label: 'Kinetics / Holographic Kinetic' },
  { key: 'laser-therapy', label: 'Laser Therapy' },
  { key: 'laughter-therapy', label: 'Laughter Therapy' },
  { key: 'light-therapy', label: 'Light Therapy' },
  { key: 'lymphatic-therapy', label: 'Lymphatic Therapy' },
  { key: 'magnet-therapy-healing-radionics', label: 'Magnet Therapy / Healing / Radionics' },
  { key: 'martial-arts', label: 'Martial Arts' },
  { key: 'massage-therapy', label: 'Massage Therapy' },
  { key: 'matrix-energetics', label: 'Matrix Energetics' },
  { key: 'medical-intuitive', label: 'Medical Intuitive' },
  { key: 'meditation', label: 'Meditation' },
  { key: 'naturopathy-naturopathic-medicine', label: 'Naturopathy / Naturopathic medicine' },
  { key: 'numerology', label: 'Numerology' },
  { key: 'nutritional-therapy-nutritionist-grahamism', label: 'Nutritional Therapy / Nutritionist / Grahamism' },
  { key: 'nutritional-supplements', label: 'Nutritional Supplements' },
  { key: 'osteopathy', label: 'Osteopathy' },
  { key: 'past-life-regression-rebirthing', label: 'Past Life Regression / Rebirthing' },
  { key: 'paula-method-healing-exercises', label: 'Paula Method Healing Exercises' },
  { key: 'physical-exercise-sports-therapy-personal-trainer-gyrotonic', label: 'Physical Exercise - Sports Therapy / Personal Trainer / Gyrotonic' },
  { key: 'physical-therapy-recreational-therapy-spinal-decompression-alexander-technique-manipulative-therapy-trigger-point', label: 'Physical Therapy / Recreational Therapy / Spinal Decompression / Alexander Technique / Manipulative Therapy / Trigger Point' },
  { key: 'psychophysical-therapy-trager-approach', label: 'Psychophysical Therapy / Trager approach' },
  { key: 'psychotherapy-psychology-autogenic-training-attachment-therapy-autosuggestion-introspection-rundown', label: 'Psychotherapy / Psychology / Autogenic Training / Attachment Therapy / Autosuggestion / Introspection Rundown' },
  { key: 'rehabilitation-physiotherapy', label: 'Rehabilitation / Physiotherapy' },
  { key: 'reconnective-healing', label: 'Reconnective Healing' },
  { key: 'reflexology', label: 'Reflexology' },
  { key: 'rolfing-structural-integration-structural-integration', label: 'Rolfing Structural Integration / Structural Integration' },
  { key: 'salt-therapy', label: 'Salt Therapy' },
  { key: 'skin-therapies', label: 'Skin Therapies' },
  { key: 'sleep-therapy', label: 'Sleep Therapy' },
  { key: 'sound-therapy-gong-bath', label: 'Sound Therapy / Gong Bath' },
  { key: 'speech-voice-therapy', label: 'Speech / Voice Therapy' },
  { key: 'support-groups', label: 'Support Groups' },
  { key: 'tantric-sexual-therapy', label: 'Tantric / Sexual Therapy' },
  { key: 'theta-healing', label: 'Theta Healing' },
  { key: 'visualisation-therapy-creative-visualisation-guided-therapy', label: 'Visualisation Therapy / Creative Visualisation / Guided Therapy' },
  { key: 'vitamin-therapy', label: 'Vitamin Therapy' },
  { key: 'water-therapy-aqua-therapy-thalassotherapy-balneotherapy-hydrotherapy', label: 'Water therapy / Aqua-therapy / Thalassotherapy / Balneotherapy / Hydrotherapy' },
  { key: 'yoga-pilates-tai-chi-qigong-asahi-health-tao-yin', label: 'Yoga / Pilates / Tai Chi / Qigong / Asahi Health / Tao Yin' },
];

export const certificate = [
  { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  { key: '200h', label: 'Registered yoga teacher 200h' },
  { key: '500h', label: 'Registered yoga teacher 500h' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};
