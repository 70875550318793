import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
// import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  // IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  // const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <IconSocialMediaTwitter />
  //   </ExternalLink>
  // ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <a href="https://faq-97cf45.webflow.io/" target="_blank" rel="noopener noreferrer" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="https://faq-97cf45.webflow.io/" target="_blank" rel="noopener noreferrer" className={css.link}>
                    <FormattedMessage id="Footer.toHelpPage" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="mailto:support@mitribewellness.com" target="_blank" rel="noopener noreferrer" className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=England%2C%20United%20Kingdom&bounds=55.8845669269515%2C1.86739999988999%2C49.8026640114418%2C-6.45033896244944',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchEngland" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Scotland%2C%20United%20Kingdom&bounds=60.9454539947733%2C-0.661268007390864%2C54.5330840033074%2C-8.71865998256',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchScotland" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Wales%2C%20United%20Kingdom&bounds=53.531201997068%2C-2.65008268983741%2C51.2856200033031%2C-5.41896792307291',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchWales" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <a href="https://www.iubenda.com/terms-and-conditions/70820964" target="_blank" rel="noopener noreferrer" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.iubenda.com/privacy-policy/70820964" target="_blank" rel="noopener noreferrer" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <a href="https://www.iubenda.com/privacy-policy/70820964" target="_blank" rel="noopener noreferrer" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </a>
              <a href="https://www.iubenda.com/terms-and-conditions/70820964" target="_blank" rel="noopener noreferrer" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
