import React from 'react';
import PropTypes from 'prop-types';
import logo from './newLogo.png';

const IconLogo = props => {
  const { className, format } = props;

  if (format === 'desktop') {
    return (
      <img className={className} src={logo} alt="Logo" />
    );
  }

  return (
    <img className={className} src={logo} alt="Logo" />
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
