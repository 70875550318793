import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionWatchVideo.css';

const SectionWatchVideo = props => {
  const { rootClassName, className, videoLink } = props;

  const videoSection = classNames(rootClassName || css.root, className);
  return (
    <div className={videoSection}>
      <div className={css.videoWrapper}>
        <iframe className={css.videoItem}  src={videoLink} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  );
};

SectionWatchVideo.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionWatchVideo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWatchVideo;
