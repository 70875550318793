import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.css';

const SectionFeaturesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.categories ? publicData.categories : [];
  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.categories"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions > 5}
      />
    </div>
  );
};

export default SectionFeaturesMaybe;

// import React from 'react';
// import { FormattedMessage } from '../../util/reactIntl';
// import { PropertyGroup } from '../../components';
//
// import css from './ListingPage.css';
//
// const SectionFeaturesMaybe = props => {
//   const { publicData } = props;
//   if (!publicData) {
//     return null;
//   }
//
//   const selectedOptions = publicData && publicData.categories ? publicData.categories : [];
//   return (
//     <div className={css.sectionFeatures}>
//       <h2 className={css.featuresTitle}>
//         <FormattedMessage id="ListingPage.featuresTitle" />
//       </h2>
//       <PropertyGroup
//         id="ListingPage.categories"
//         options={selectedOptions}
//         selectedOptions={selectedOptions}
//         twoColumns={selectedOptions > 5}
//       />
//     </div>
//   );
// };
//
// export default SectionFeaturesMaybe;
