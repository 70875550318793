import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './AboutPage.css';
import image from './about-us-1056.jpg';

const AboutPage = () => {
  const { siteTwitterHandle, siteInstagramPage, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Mitribe',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>About us</h1>
          <img className={css.coverImage} src={image} alt="My first ice cream." />

          <div className={css.contentWrapper}>
            {/*<div className={css.contentSide}>*/}
            {/*  <p>Yoga was listed by UNESCO as an intangible cultural heritage.</p>*/}
            {/*</div>*/}

            <div className={css.contentMain}>
              <h2>Welcome to MiTribe Wellness!</h2>

              <p>
                A virtual health and wellness community, where you can learn about different therapies and talk
                to like-minded people. Help clients open to new age, mainstream, and old world healing
                therapies. Find trusted practitioners from across different modalities. We know there
                are many ways to walk the path to wellness.
                We invite you to join us and open up to the discoveries on your healing journey.
              </p>

              <h3 className={css.subtitle}>Are You A Health and Wellness Practitioner?</h3>

              <p>
                MiTribe Wellness is your opportunity to showcase your services and access an engaged client
                base. Link your profile to your own homepage and use it as your booking system, or cut out the
                middleman and use this as your primary site to attract like-minded clients. Whatever you decide,
                how you use it is up to you. Choose your own availability and rates, for a truly flexible
                experience.
                Setting up your health and wellness practice just became simpler than ever.
              </p>

              <h3 className={css.subtitle}>Looking For Health and Wellness Services?</h3>

              <p>
                With so much of what we do and buy going online, it makes sense that you would want digital
                access to your health and wellness professional. With a huge database onhand and a simple,
                streamlined access panel, you’ll find the right service provider for you in next-to-no time. And,
                really, isn’t that kind of access what matters most when you need to speak to a professional
                fast?
              </p>

              <p>
                You can also checkout our{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteInstagramPage}>Instagram</ExternalLink>.
              </p>

              <h3 className={css.subtitle}>Contact Us</h3>

              <p>
                Have any questions you’d like for us to answer? We’d love to hear from you! Get in touch with
                us, and a representative will get back to you.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
