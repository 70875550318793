import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureListing, ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.css';

const { Money } = sdkTypes;

export default class EditListingPricingPanel extends React.Component {

  static CENT_FACTOR = 100;

  state = { data: []};

  componentDidMount() {
    const currentListing = ensureListing(this.props.listing);
    const { publicData } = currentListing.attributes;
    const data = publicData.services !== undefined ? publicData.services : this.props.services;
    this.setState({
      data,
      publicData
    })
  }

  handleChange = (value, categoryIndex, name) => {
    const data = this.state.data.slice();
    const amount = value * EditListingPricingPanel.CENT_FACTOR;
    this.setState({
      data,
      [name]: {amount: amount, currency: config.currency }
    })
  };

  render() {
    const {
      className,
      rootClassName,
      listing,
      onSubmit,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { price } = currentListing.attributes;

    const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPricingPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingPricingPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
    );

    const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
    const form = priceCurrencyValid ? (
      <EditListingPricingForm
        className={css.form}
        initialValues={{ price, values: this.state }}
        onSubmit={onSubmit}
        onChange={this.handleChange}
        data={this.state.data}
        publicData={this.state.publicData}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        listing={listing}
      />
    ) : (
      <div className={css.priceCurrencyInvalid}>
        <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
      </div>
    );
    return(
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        {form}
      </div>
    );
  }
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  categories: config.custom.categories,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
