import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';

import css from './BookingPanel.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();
const CURRENCY = 'GBP';
const CENT_FACTOR = 100;

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

class BookingPanel extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      price: 0
    };
  }

  render() {
    const {
      rootClassName,
      className,
      titleClassName,
      listing,
      isOwnListing,
      unitType,
      onSubmit,
      title,
      subTitle,
      onManageDisableScrolling,
      onFetchTimeSlots,
      monthlyTimeSlots,
      history,
      location,
      intl,
    } = this.props;

    const timeZone =
      listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
    const hasListingState = !!listing.attributes.state;
    const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
    const showBookingTimeForm = hasListingState && !isClosed;
    const showClosedListingHelpText = listing.id && isClosed;
    const isBook = !!parse(location.search).book;

    const subTitleText = !!subTitle
      ? subTitle
      : showClosedListingHelpText
        ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
        : null;

    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'BookingPanel.perNight'
      : isDaily
        ? 'BookingPanel.perDay'
        : 'BookingPanel.perUnit';

    const classes = classNames(rootClassName || css.root, className);
    const titleClasses = classNames(titleClassName || css.bookingTitle);
    const services = listing.attributes.publicData.services ? listing.attributes.publicData.services : [];

    const additionalItems = services.map(e => {
      return { [e.key]: new Money(listing.attributes.publicData[e.key] ? listing.attributes.publicData[e.key].amount : 0,
          listing.attributes.publicData[e.key] ? listing.attributes.publicData[e.key].currency : config.currencyConfig.currency)}
    });

    const handleSubmit = values => {
      onSubmit({
        ...values,
      });
    };

    const newPrice = new Money(this.state.price * CENT_FACTOR, CURRENCY);

    return (
      <div className={classes}>
        <ModalInMobile
          containerClassName={css.modalContainer}
          id="BookingTimeFormInModal"
          isModalOpenOnMobile={isBook}
          onClose={() => closeBookModal(history, location)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.modalHeading}>
            <h1 className={css.title}>{title}</h1>
          </div>
          <div className={css.bookingHeading}>
            <div className={css.bookingHeadingContainer}>
              <h2 className={titleClasses}>{title}</h2>
              <span>{intl.formatMessage({ id: 'BookingPanel.rightTime' })}</span>
            </div>
          </div>

          {showBookingTimeForm ? (
            <BookingTimeForm
              className={css.bookingForm}
              formId="BookingPanel"
              submitButtonWrapperClassName={css.submitButtonWrapper}
              unitType={unitType}
              onSubmit={handleSubmit}
              price={newPrice}
              isOwnListing={isOwnListing}
              listingId={listing.id}
              listing={listing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
              services={services}
              additionalItems={additionalItems}
            />
          ) : null}
        </ModalInMobile>
        <div className={css.openBookingForm}>
          {showBookingTimeForm ? (
            <Button
              rootClassName={css.bookButton}
              onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
            >
              <FormattedMessage id="BookingPanel.ctaButtonMessage" />
            </Button>
          ) : (
            <div className={css.closedListingButton}>
              <FormattedMessage id="BookingPanel.closedListingButtonText" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
