/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError } from '../../components';

import css from './FieldCategoryCheckboxGroup.css';

const FieldCategoryCheckboxRenderer = props => {
  const { className, rootClassName, label, twoColumns, id, meta, onChange } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  const renderList = (option, categoryIndex) => {
    const label = option.label;
    return option.subcategories.map((subcategory, index) => {
      const fieldId = `subcategory${label.charAt(0).toUpperCase() + label.substring(1)}.${subcategory.key}`;
      return (
        <li style={option.checked ? { display: 'block' } : { display: 'none' }} key={fieldId}
            className={css.item}>
          <FieldCheckbox
            id={fieldId}
            name={subcategory.name}
            label={subcategory.label}
            checked={subcategory.checked}
            onChange={(e) => onChange(e, categoryIndex, subcategory, index)}
          />
        </li>);

    });
  };

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {props.data.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                id={fieldId}
                name={option.key}
                label={option.label}
                value={props.data[index] && props.data[index].checked}
                checked={props.data[index] && props.data[index].checked}
                onChange={(e) => props.handleCategoryChange(e, option, index)}
              />
              <fieldset className={classes}>
                {label ? <legend>{label}</legend> : null}
                <ul className={listClasses}>
                  {renderList(option, index)}
                </ul>
                <ValidationError fieldMeta={{ ...meta }}/>
              </fieldset>
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }}/>
    </fieldset>
  );
};

FieldCategoryCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldCategoryCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    }),
  ),
  twoColumns: bool,
};

const FieldCategoryCheckboxGroup = props => <FieldArray
  component={FieldCategoryCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCategoryCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldCategoryCheckboxGroup;
