import React, { Component } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form } from '../../components';
import ButtonMaterial from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import css from './EditListingFeaturesForm.css';

class EditListingFeaturesFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            handleSubmit,
            onChangeForm,
            services,
            state,
            categories,
            onAddService,
            onRemoveForm,
            handleChangeInput,
            removeService,
            pristine,
            inputsDisplay,
            saveActionMsg,
            updated,
            defaultValues,
            updateInProgress,
            fetchErrors,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress;

          const { updateListingError, showListingsError } = fetchErrors || {};
          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
            </p>
          ) : null;

          const useStyles = makeStyles(theme => ({
            root: {
              '& > * + *': {
                marginTop: theme.spacing(3),
              }
            },
            formControl: {
              margin: theme.spacing(1),
              minWidth: 120,
            },
            selectEmpty: {
              marginTop: theme.spacing(2),
            },
          }));

          const useStylesButton = makeStyles((theme) => ({
            root: {
              '& > *': {
                margin: theme.spacing(1),
              },
            },
          }));

          const serviceNameMessage = intl.formatMessage({
            id: 'EditListingFeaturesForm.serviceName',
          });
          const serviceNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingFeaturesForm.serviceNamePlaceholder',
          });

          const serviceDescriptionMessage = intl.formatMessage({
            id: 'EditListingFeaturesForm.serviceDescription',
          });
          const serviceDescriptionPlaceholderMessage = intl.formatMessage({
            id: 'EditListingFeaturesForm.serviceDescriptionPlaceholder',
          });
          const classesAutocomplete = useStyles();
          const sortedCategories = config.custom.categories.map(option => option.label).sort();

          const classesButton = useStylesButton();
          const renderHours = () => {
            return [...Array(25).keys()].map(i => {
              return (
                <MenuItem value={i}>{i < 10 ? `0${i}` : i}</MenuItem>
              )
            });
          };

          const renderServices = (services) => {
            if (services !== undefined && services.length > 0) {
              return services.map((service, key) => (
                <div key={key}>
                  <p><FormattedMessage id="EditListingFeaturesForm.serviceName" />: {service.name}</p>
                  {service.description.length > 0 ? <p><FormattedMessage id="EditListingFeaturesForm.serviceDescription"  />: {service.description}</p> : null}
                  <p><FormattedMessage id="EditListingFeaturesForm.serviceDuration" values={{ hours: parseInt(service.duration), minutes: service.duration % 1 === 0.5 ? 30 : service.duration % 1 === 0.25 ? 15 : service.duration % 1 === 0.75 ? 45 : 0}} /></p>
                  {services.length > 1 ? <div className={classes.root}>
                    <IconButton aria-label="delete" onClick={removeService} value={service.key}>
                      <DeleteIcon />
                    </IconButton>
                  </div> : null}
                </div>
              ))
            }
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              {errorMessageShowListing}

              <div className={classesAutocomplete.root}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  onChange={(e, v) => { onChangeForm(e, v) }}
                  defaultValue={defaultValues.length > 0 ? defaultValues.map(categoryIndex => config.custom.categories[categoryIndex].label) : []}
                  options={sortedCategories}
                  freeSolo
                  renderTags={(value, getTagProps) => {
                    const lastElement = value.length > 3 && value.splice(-1, 1);
                    return value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }}
                  renderInput={params => {
                    return <TextField {...params} variant="filled" placeholder="Add category" />;
                  }
                  }
                />
              </div>
              <p className={css.tip}>
                <FormattedMessage id="EditListingFeaturesForm.tip" />
              </p>

              {renderServices(services)}

              {inputsDisplay ? <TextField
                id="standard-basic"
                name="serviceName"
                className={css.description}
                label={serviceNameMessage}
                value={state.serviceName}
                placeholder={serviceNamePlaceholderMessage}
                onChange={e => handleChangeInput(e.currentTarget.name, e.target.value)}
                required={true}
              /> : null}

              {inputsDisplay ? <TextField
                id="standard-basic"
                name="serviceDescription"
                className={css.description}
                label={serviceDescriptionMessage}
                value={state.serviceDescription}
                placeholder={serviceDescriptionPlaceholderMessage}
                onChange={e => handleChangeInput(e.currentTarget.name, e.target.value)}
              /> : null}

              {inputsDisplay ? <p>Service Duration:</p> : null}
              {inputsDisplay ? <div><FormControl className={css.time}>
                <InputLabel id="demo-simple-select-required-label">Hours</InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  name="serviceDurationHours"
                  value={state.serviceDurationHours}
                  onChange={e => handleChangeInput(e.target.name, e.target.value)}
                >
                  {renderHours()}
                </Select>
              </FormControl>
              <FormControl className={css.time}>
                <InputLabel id="demo-simple-select-required-label">Minutes</InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  name="serviceDurationMinutes"
                  value={state.serviceDurationMinutes}
                  onChange={e => handleChangeInput(e.target.name, e.target.value)}
                >
                  {state.serviceDurationHours > 0 ? <MenuItem value={0}>00</MenuItem> : null}
                  {state.serviceDurationHours < 24 ? <MenuItem value={15}>15</MenuItem> : null}
                  {state.serviceDurationHours < 24 ? <MenuItem value={30}>30</MenuItem> : null}
                  {state.serviceDurationHours < 24 ? <MenuItem value={45}>45</MenuItem> : null}
                </Select>
              </FormControl></div> : null}
              {inputsDisplay ? <div className={classesButton.root}>
                <ButtonMaterial color="primary" onClick={onRemoveForm}>
                  <FormattedMessage id="EditListingFeaturesForm.removeForm" />
                </ButtonMaterial>
              </div> : null}
              <div className={classesButton.root}>
                <ButtonMaterial color="primary" onClick={onAddService}>
                  <FormattedMessage id="EditListingFeaturesForm.addNewService" />
                </ButtonMaterial>
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                ready={submitReady}
                disabled={!((categories !== undefined && categories.length > 0) && ((services !== undefined && services.length > 0) ||
                          (state.serviceName !== undefined && state.serviceName.length > 0 && (state.serviceDurationHours > 0 || state.serviceDurationMinutes > 0))))}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    )
  }
}

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
