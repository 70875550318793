import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, PrimaryButton, FieldCheckbox } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { formatMoney } from '../../util/currency';

import css from './BookingTimeForm.css';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxDuration: 0,
      currentDuration: 0,
      services: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  handleFormSubmit(e) {
    e.additionalItems = this.state.services;
    this.props.onSubmit(e);
  }

  onChangeDate(start, end) {
    const duration = (end - start)/60/60/1000;
    this.setState({maxDuration: duration});
    this.setState({services: []});
    this.setState({currentDuration: 0});
  }

  onCheckedAdditionalItem(duration, serviceKey, e) {
    const services = Object.assign(this.state.services);
    const currentDuration = Object.assign(this.state.currentDuration);
    if (e.currentTarget.checked) {
      this.setState({currentDuration: currentDuration + duration});
      services.push(serviceKey);
    } else {
      this.setState({currentDuration: currentDuration - duration});
      const index = services.indexOf(serviceKey);
      services.splice(index, 1);
    }
    this.setState({services});
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            formId,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            listing,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            services,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            additionalItems
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const res = this.state.services ? {} : null;
          if (this.state.services) {
            this.state.services.forEach((itemSelect) => {
              additionalItems.forEach((item) => {
                if (Object.keys(item) == itemSelect) {
                  res[itemSelect] = item[Object.keys(item)];
                }
              })
            });
          }

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  unitPrice,
                  startDate,
                  endDate,
                  quantity: 1,
                  timeZone,
                  ...res
                }
              : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} selectedItems={this.state.services} listing={listing}/>
            </div>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const renderList = (additionalItems) => {
            return additionalItems.map((item, i) => {
              const key = Object.keys(item);
              const service = services.find(s => s.key === key[0]);
              const addClass = this.state.services.indexOf(key[0]) === -1 && (parseFloat(service.duration) > this.state.maxDuration || parseFloat(service.duration) + this.state.currentDuration > this.state.maxDuration) ? css.blockService : 'activeService';
              return (
                <div className={css.cleaningFee} key={key[0]}>
                  <FieldCheckbox
                    className={`${addClass} additionalItems`}
                    id={`${formId}.${key[0]}`}
                    label={intl.formatMessage({ id: 'BookingDatesForm.category' }, { label: service.name })}
                    name='additionalItems'
                    value={key[0]}
                    checked={this.state.services.indexOf(key[0]) !== -1}
                    servicekey={key[0]}
                    disabled={this.state.services.indexOf(key[0]) === -1 && (parseFloat(service.duration) > this.state.maxDuration || parseFloat(service.duration) + this.state.currentDuration > this.state.maxDuration)}
                    duration={parseFloat(service.duration)}
                    onChange={e => this.onCheckedAdditionalItem(parseFloat((e.currentTarget).getAttribute('duration')), (e.currentTarget).getAttribute('servicekey'), e)}
                  />
                  <span className={`${css.cleaningFeeAmount} ${addClass}`}>{formatMoney(intl, additionalItems[i][key])}</span>
                </div>
                )
              });
          };
          const additionalItemsExist = this.state.services.length === 0;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  listing={listing}
                  onChangeDate={this.onChangeDate}
                  currentDuration={this.state.currentDuration}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                />
              ) : null}
              {Object.keys(additionalItems).length !== 0 ? renderList(additionalItems) : null}
              {this.state.services.length > 0 ? bookingInfo : null}
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={additionalItemsExist}>
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
